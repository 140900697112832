// Base route
import Login from '@/views/Login.vue';
import LoginChristmas from '@/views/LoginChristmas.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import VerifySuccess from '@/views/VerifySuccess.vue';
import MyBalance from '@/views/MyBalance.vue';
import Setting from '@/views/Setting.vue';
import Register from '@/views/Register.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import NotFound from '@/views/404.vue';
import Menu from '@/views/Menu.vue';

// Layout
import LayoutMenu from '@/layouts/LayoutMenu.vue';
import LayoutDefault from '@/layouts/LayoutDefault.vue';

export let baseRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Menu,
    meta: {
      layout: LayoutMenu,
      auth: true,
      checkRole: true,
    },
  },
  // Auth
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Halaman Login',
    },
  },
  {
    path: '/newlogin',
    name: 'New  Login',
    component: LoginChristmas,
    meta: {
      title: 'Login Page',
    },
  },
  {
    path: '/saldo',
    name: 'MyBalance',
    component: MyBalance,
    meta: {
      auth: true,
      title: 'Saldo Saya',
      layout: LayoutDefault,
    },
  },
  {
    path: '/settings',
    name: 'Pengaturan',
    component: Setting,
    meta: {
      auth: true,
      title: 'Pengaturan Akun',
      layout: LayoutDefault,
    },
  },
  {
    path: '/settings/:id',
    name: 'settings.office',
    component: Setting,
    meta: {
      auth: true,
      title: 'Pengaturan Akun',
      layout: LayoutDefault,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: 'Register Page',
    },
  },
  {
    path: '/lupa-password',
    name: 'LupaPassword',
    component: ForgotPassword,
    meta: {
      title: 'Lupa Password',
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'Not Found',
    },
  },
  {
    path: '/email/verify/success',
    name: 'Verify Success',
    component: VerifySuccess,
    meta: {
      title: 'Verify Success',
    },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      title: 'Reset Password',
    },
  },
];
